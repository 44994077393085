import { createElement } from "react";
import styled, { css } from "styled-components";
import Link from "components/layout/Link";
import Text from "components/typography/Text";

const NameContainerLink = styled(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({
		oneLine,
		padded,
		size,
		maxWidthWithEllipsis,
		wordBreak,
		scrollForce,
		noUnderline,
		...rest
	}) => <Link {...rest} />
)`
	display: flex;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;

	${({ $customMargin }) =>
		$customMargin &&
		css`
			margin: ${$customMargin};
		`}

	${({ wordBreak }) =>
		wordBreak &&
		css`
			width: 100%;
			word-break: ${wordBreak};
		`}

	${({ color }) =>
		color &&
		css`
			color: ${color} !important;

			&:visited {
				color: ${color} !important;
			}
		`}

	${({ oneLine }) =>
		oneLine &&
		css`
			display: flex;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`}


	${({ maxWidthWithEllipsis }) =>
		maxWidthWithEllipsis &&
		css`
			max-width: ${typeof maxWidthWithEllipsis === "number"
				? `${maxWidthWithEllipsis}px`
				: maxWidthWithEllipsis};
			white-space: nowrap;
		`}


	${({ padded }) =>
		padded &&
		css`
			padding: 0 0.5rem;
			line-height: 1.5rem;
		`}

	font-size:${({ size }) => size || "1rem"};

	&:hover {
		text-decoration: underline !important;
	}

	${({ noUnderline }) =>
		noUnderline &&
		css`
			&:hover {
				text-decoration: none !important;
			}
		`}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NameContainer = styled(
	({
		oneLine,
		padded,
		size,
		maxWidthWithEllipsis,
		wordBreak,
		color,
		...props
	}) => createElement("span", props)
)`
	display: flex;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;

	${({ color }) =>
		color &&
		css`
			color: ${color} !important;
		`}

	${({ oneLine }) =>
		oneLine &&
		css`
			display: flex;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`}

	${({ maxWidthWithEllipsis }) =>
		maxWidthWithEllipsis &&
		css`
			max-width: ${typeof maxWidthWithEllipsis === "number"
				? `${maxWidthWithEllipsis}px`
				: maxWidthWithEllipsis};
			white-space: nowrap;
		`}

	${({ wordBreak }) =>
		wordBreak &&
		css`
			word-break: ${wordBreak};
		`}

	${({ padded }) =>
		padded &&
		css`
			padding: 0 0.5rem;
			line-height: 1.5rem;
		`}

	font-size:${({ size }) => size || "1rem"};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainText = styled(({ isProfile, ...props }) => <Text {...props} />)`
	overflow: hidden;
	text-overflow: ellipsis;

	${({ isProfile }) => {
		return (
			isProfile &&
			css`
				overflow-wrap: anywhere;
			`
		);
	}}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NamePart = styled(({ oneLine, showUnverified, ...props }) =>
	createElement("span", props)
)`
	display: inline;
	${({ oneLine }) =>
		oneLine &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
		`}

	${({ showUnverified }) =>
		showUnverified &&
		css`
			margin-left: 0.25rem;
		`}

		
	${({ $append }) =>
		$append &&
		css`
			display: inline-flex;
			align-items: center;
		`}
`;

const Name = ({
	padded,
	oneLine,
	to,
	content,
	prepend,
	append,
	size,
	color = "primary",
	wordBreak,
	scrollForce,
	closeModal,
	maxWidthWithEllipsis,
	isProfile,
	statusVip,
	showUnverified = false,
	customMargin,
	noUnderline,
	...rest
}) => {
	const renderPart = (part) => {
		const partContent = typeof part === "function" ? part() : part;
		return partContent ? <NamePart>{partContent}</NamePart> : null;
	};

	const renderContent = () => {
		return (
			<>
				{renderPart(prepend)}
				<MainText color={color} bold isProfile={isProfile} {...rest}>
					{content}
					{isProfile && (
						<NamePart
							showUnverified={showUnverified}
							$append={append}
						>
							{statusVip}
							{append}
						</NamePart>
					)}
				</MainText>
				{!isProfile && <NamePart>{statusVip}</NamePart>}
			</>
		);
	};
	if (to) {
		return (
			<NameContainerLink
				to={to}
				padded={padded}
				oneLine={oneLine}
				title={content}
				size={size}
				maxWidthWithEllipsis={maxWidthWithEllipsis}
				wordBreak={wordBreak}
				scrollForce={scrollForce}
				closeModal={closeModal}
				color={color}
				$customMargin={customMargin}
				noUnderline={noUnderline}
				{...rest}
			>
				{renderContent()}
			</NameContainerLink>
		);
	}
	return (
		<NameContainer
			padded={padded}
			oneLine={oneLine}
			title={content}
			size={size}
			maxWidthWithEllipsis={maxWidthWithEllipsis}
			wordBreak={wordBreak}
		>
			{renderContent()}
		</NameContainer>
	);
};

Name.displayName = "Name";

export { NameContainer, NameContainerLink };
export default Name;
